import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import H1 from 'components/Layout/H1';

const Privacy = (): ReactElement => {
    const { t, i18n } = useTranslation('static');

    return (
        <React.Fragment>
            <Helmet>
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_SITE_URL}${
                        i18n.language === 'fr' ? 'politique-de-confidentialite' : 'privacy-policy'
                    }`}
                />
                <meta property="og:site_name" content={t('static:privacy.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('static:privacy.meta.og.title')} />
                <meta property="og:description" content={t('static:privacy.meta.og.description')} />
                <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
                <meta property="twitter:title" content={t('static:privacy.meta.twitter.title')} />
                <meta property="twitter:description" content={t('static:privacy.meta.twitter.description')} />
                <meta property="twitter:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('static:privacy.meta.title')} />
                <title>{t('static:privacy.meta.title')}</title>
                <meta property="description" content={t('static:privacy.meta.description')} />
            </Helmet>
            <Container className="text-justify pb-5">
                <Row>
                    <Col xs={12} className="text-center mb-4">
                        <H1>
                            <Trans t={t}>static:privacy.title</Trans>
                        </H1>
                    </Col>
                    <Col xs={12}>
                        <p>
                            <Trans t={t}>static:privacy.paragraphs.p1</Trans>
                        </p>
                        <p>
                            <Trans t={t}>static:privacy.paragraphs.p2</Trans>
                        </p>
                        <p>
                            <Trans t={t}>static:privacy.paragraphs.p3</Trans>
                        </p>
                        <ul>
                            <li>
                                <Trans t={t}>static:privacy.bullets.b1</Trans>
                            </li>
                            <li>
                                <Trans t={t}>static:privacy.bullets.b2</Trans>
                            </li>
                            <li>
                                <Trans t={t}>static:privacy.bullets.b3</Trans>
                            </li>
                            <li>
                                <Trans t={t}>static:privacy.bullets.b4</Trans>
                            </li>
                        </ul>
                        <p>
                            <Trans t={t}>static:privacy.paragraphs.p4</Trans>
                        </p>
                        <p>
                            <Trans t={t}>static:privacy.paragraphs.p5</Trans>
                        </p>
                        <p>
                            <Trans t={t} i18nKey="static:privacy.paragraphs.p6">
                                <a href="mailto:contact.gpdr@idealstbarth.com" />
                            </Trans>
                        </p>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Privacy;
